<template>
    <div class="select">
        <div class="table-container">
            <div class="button_container">
                所属省份：
                <el-select size="small" v-model="provinceId" placeholder="请选择" class="select_container input"
                           @change="onProvince()">
                    <el-option
                            v-for="item in this.$store.state.school.province.list"
                            :key="item.id"
                            :label="item.provinceName"
                            :value="item.id">
                    </el-option>
                </el-select>
                所属城市：
                <el-select size="small" v-model="cityId" placeholder="请选择" class="select_container input"
                           @change="onCity">
                    <el-option
                            v-for="item in this.$store.state.school.city.list"
                            :key="item.index"
                            :label="item.cityName"
                            :value="item.id">
                    </el-option>
                </el-select>
                所属区县：
                <el-select size="small" v-model="areaId" placeholder="请选择" class="select_container input">
                    <el-option
                            v-for="item in this.$store.state.school.area.list"
                            :key="item.index"
                            :label="item.areaName"
                            :value="item.id">
                    </el-option>
                </el-select>
                学校名称：
                <el-input size="small" placeholder="请选择" class="school_input select_container input"
                          v-model="schoolName"></el-input>
                <el-button size="small" type="primary" class="add_button select_container" @click="onSearch">查询
                </el-button>
                <el-button size="small" type="primary" class="add_button select_container" @click="onReset">重置
                </el-button>
                <el-button size="small" type="primary" class="add_button select_container" @click="addSchool">添加学校
                </el-button>
                <div class="upload_container">
                    一键上传
                    <input size="small" type="file" class="upload_input" value="excel" @change="onExcel">
                </div>
                <el-button type="primary" size="small" @click="onGenerate" class="add_button select_container">生成数据</el-button>
                <el-button type="primary" size="small" @click="downloadFile" class="add_button select_container">下载数据</el-button>
            </div>
            <el-table border :data="this.$store.state.school.school.list">
                <el-table-column type="index" :index="indexCompute" label="序号" align="center"
                                 width="80px"></el-table-column>
                <el-table-column prop="schoolName" label="学校名称" align="center" min-width="200px"></el-table-column>
                <el-table-column prop="provinceName" label="所属省份" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="cityName" label="所属城市" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="areaName" label="所属区县" align="center" min-width="100px"></el-table-column>
                <el-table-column label="操作" align="center" width="150px">
                    <template slot-scope="scope">
                        <el-link type="primary" icon="el-icon-s-operation"
                                 @click="updateHandler(scope.row)">修改
                        </el-link>
                        <el-link type="primary" icon="el-icon-delete" style="margin-left: 10px"
                                 @click="deleteHandler(scope.row)">删除
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-container">
            <el-pagination class="pagination afterClearfix"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="pagination.page+1"
                           :page-sizes="[10, 20, 30, 50]"
                           :page-size="pagination.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="this.$store.state.school.school.total"
                           style="float: right">
            </el-pagination>
        </div>
        <router-view/>
        <el-dialog
                title="修改学校名称"
                :visible.sync="dialogVisible"
                width="600px">
            <div class="edit-container">
                <div class="row-container">
                    <div class="right-align"><span class="red">*</span>学校名称：
                    </div>
                    <el-input class="input" type="text" v-model="reSchoolName" placeholder="请输入修改后的学校名称"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" style="margin-left: 50px" @click="onRename">确 定</el-button>
                    </span>
        </el-dialog>
    </div>
</template>

<script>
    import {setData} from "@/utils/cookies";
    import axios from '@/libs/axios'

    export default {
        name: 'List',
        data() {
            return {
                pagination: {
                    page: 0,
                    size: 10,
                },
                dialogVisible: false,//添加弹框
                value: '',
                city: [],
                options: [],
                provinceId: '',//省份id
                cityId: '',//城市id
                areaId: '',//区域id
                schoolName: '',//学校名称
                reSchoolName: '',//修改后的学校名称
                reNameSchoolId: '',//修改的学校id
                reNameAreaId: '',//修改的区县id
                downloadUrl: '',//文件下载地址
                excel:''
            }
        },
        mounted() {
            this.getProvinceList();
            this.getSchoolList();
        },
        methods: {

            indexCompute(index) {
                return index + 1 + this.pagination.page * this.pagination.size;
            },


            // viewHandler(row) {
            //     setData('user', row);
            //     // this.$router.push('/user/small/see');
            // },
            handleSizeChange(size) {
                this.pagination.size = size;
                this.getSchoolList();
            },
            handleCurrentChange(page) {
                this.pagination.page = page - 1;
                this.getSchoolList();
            },
            //获取省份
            getProvinceList() {
                this.$store.dispatch("school/provinceList");

            },
            addManage() {

            },
            //省份变化
            onProvince() {
                this.$store.dispatch("school/cityList", {'id': this.provinceId});
            },
            //城市变化
            onCity() {
                this.$store.dispatch("school/areaList", {'id': this.cityId,});
            },
            // 查询
            onSearch() {
                this.pagination.page=0;
                this.getSchoolList();

            },
            //获取学校列表
            getSchoolList() {
                this.$store.dispatch("school/schoolList", {
                    'provinceId': this.provinceId,
                    'cityId': this.cityId,
                    'areaId': this.areaId,
                    'schoolName': this.schoolName,
                    'page': this.pagination.page,
                    'size': this.pagination.size,
                });
            },
            //重置
            onReset() {
                this.provinceId = '';
                this.cityId = '';
                this.areaId = '';
                this.getSchoolList()
            },
            addSchool() {
                this.$router.push('/school/school/add');
            },


            // 删除教材
            deleteHandler(row) {
                this.$store.dispatch("school/deleteSchool", {'schoolId': row.schoolId}).then((data) => {
                    if (this.$store.state.user.user.list.length === 1) {
                        this.pagination.page--
                    }
                    this.getSchoolList();
                });
            },

            // 修改管理员
            updateHandler(row) {
                this.reNameSchoolId = row.schoolId;
                this.reNameAreaId = row.areaId;
                this.dialogVisible = true;

            },
            onRename() {
                this.$store.dispatch("school/updateSchool", {
                    'schoolId': this.reNameSchoolId,
                    'areaId': this.reNameAreaId,
                    'schoolName': this.reSchoolName,
                }).then((data) => {
                    this.dialogVisible = false;
                    this.getSchoolList()

                })
            },
            //下载文件
            downloadFile() {
                this.downloadUrl = '';
                this.$store.dispatch("school/downloadFile").then((data) => {
                    if (!data.saving) {
                        this.downloadUrl = 'http://192.168.0.100/' + data.excelFileUrl;
                        this.saveShareContent(this.downloadUrl,'111.xlsx')
                    }
                });
            },
            //数据生成
            onGenerate() {
                this.$store.dispatch("school/generateFile", {
                    'provinceId': this.provinceId,
                    'cityId': this.cityId,
                    'areaId': this.areaId,
                    'schoolName': this.schoolName,
                })
            },
//保存下载的excel
            saveShareContent(content, fileName) {
                let downLink = document.createElement('a')
                downLink.download = fileName
                downLink.href = content
                // 链接插入到页面
                document.body.appendChild(downLink)
                downLink.click()
                // 移除下载链接
                document.body.removeChild(downLink)
            },
            //获取上传文件
            onExcel(e){
                console.log(e.target.files[0]);
                axios.upload("/v1/web/school/excel/upload", e.target.files[0]).then(response => {
                }).catch((e) => {
                    console.log(e)
                });

                // this.$store.dispatch("school/uploadFile",{
                //     'file':e.target.files[0]
                // })


            }
        }
    }
</script>

<style scoped lang="scss">
    .button_container {
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        align-items: center;
    }

    .school_input {
        display: inline-block;
        width: 300px;
    }

    .input {
        width: 150px;
    }

    .select_container {
        margin-right: 30px;
        margin-bottom: 30px;
    }
    .upload_container {
        display: inline-flex;
        width: 80px;
        height: 32px;
        position: relative;
        overflow: hidden;
        background-color:#409EFF;
        border-radius: 5px;
        color: #ffffff;
        line-height: 32px;
       justify-content: center;
        margin-right: 30px;
        font-size: 12px;
    }
    .upload_input {
        display: inline-block;
        position: absolute;
        top: 0;
        left: -500px;
        width: 580px;
        height: 32px;
        border: none;
        outline: none;
    }

    .edit-container {

        text-align: center;

        .title {
            margin-bottom: 50px;
        }

        .row-container {
            padding: 8px 0;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;

            /*上传图片框修饰*/
            .upload_container {
                width: 300px;
                display: inline-block;
                text-align: start;
            }

            .avatar-uploader, .el-upload {
                border: 1px dashed #d9d9d9;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                display: inline-block;
                height: 130px;
                width: 130px;
            }

            .el-upload {
                border: 1px dashed #d9d9d9;
            }

            .avatar-uploader, .el-upload:hover {

            }

            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 130px;
                height: 130px;
                line-height: 130px;
                text-align: center;
            }

            .avatar {
                width: 130px;
                height: 130px;
                display: block;
            }

            .url_image {
                height: 100%;
                margin-right: 10px;
                display: flex;
                justify-content: center;
            }

            .right-align {
                display: inline-block;
                text-align: right;
                width: 110px;
                height: 30px;
                line-height: 30px;
            }

            .red {
                color: red;
                padding: 5px;
            }

            .text {
                padding-left: 25px;
            }

            .input {
                display: inline-block;
                width: 300px;
                padding-left: 10px;
            }

            .textarea_container {
                height: 50px;
            }

            .textarea_text {
                height: 50px;
                line-height: 50px;
            }

            .switch_class {
                text-align: left;
            }

            .state_class {
                display: inline-block;
                height: 20px;
                line-height: 20px;
                vertical-align: bottom;
                margin-left: 8px;
            }
        }

        .password-tip {
            margin-left: 185px;
            padding: 5px;
            width: 315px;
            text-align: left;
            color: #aaaaaa;
            display: block;
        }

        .action-container {
            margin-top: 30px;
            margin-left: 130px;
        }

        .save {
            padding: 12px 28px;
        }

        .cancel {
            margin-left: 20px;
            padding: 12px 28px;
        }

    }


</style>
